<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="redirect.id" @deleteRecord="deleteRedirect"></app-button-delete>
              <app-button-close route-name="redirect_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <input type="hidden" v-model="redirect.id">

                    <app-select
                      v-model="redirect.site"
                      :options="sites"
                      :label="$t('redirect.site')"
                      id="redirect_site"
                    >
                    </app-select>

                    <app-input
                      v-model="redirect.sourceUrl"
                      @blur="$v.redirect.sourceUrl.$touch()"
                      :error="$v.redirect.sourceUrl.$error"
                      id="redirect_sourceUrl"
                      :label="$t('redirect.source_url')"
                    >
                    </app-input>

                    <app-input
                      v-model="redirect.destinationUrl"
                      @blur="$v.redirect.destinationUrl.$touch()"
                      :error="$v.redirect.destinationUrl.$error"
                      id="redirect_destinationUrl"
                      :label="$t('redirect.destination_url')"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import Input from '../../components/form/inputs/Input'
import Select from '../../components/form/select/Select'
import { required } from 'vuelidate/lib/validators'
import RedirectModel from '../../model/Redirect'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'RedirectNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      redirect: this._.cloneDeep(RedirectModel)
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites']()
    }
  },
  validations: {
    redirect: {
      sourceUrl: {
        required
      },
      destinationUrl: {
        required
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.$store.dispatch('redirect/create', this.redirect)
          .then(() => {
            if (this.$store.getters['redirect/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.redirect = this.$store.getters['redirect/detail']
              this.$router.push('/redirect/' + this.redirect.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['redirect/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deleteRedirect () {
      this.$store.dispatch('redirect/deleteRecord', this.redirect)
        .then(() => {
          if (this.$store.getters['redirect/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/redirect')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['redirect/error'])
          }
        })
        .catch(error => console.log(error))
    }
  }
}
</script>
